import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SliderBlock from "../SliderBlock";
import { ImageInterface, PortableText, MultiLineHeadline } from "../../types/SanityTypes";
import { useStaticQuery, graphql } from "gatsby";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import "./styles.scss";

export interface GenericSliderInterface {
  sliderType: {
    name: string;
    description: string;
  };
  bgColor?: {
    title: string;
    value: string;
  };
  headingColor?: {
    title: string;
    value: string;
  };
  slidesQuantity: number;
  headline: MultiLineHeadline;
  _rawSubHeading?: PortableText;
  content: {
    _rawImage: ImageInterface;
    url: string;
    title: string;
    // For Preview
    image?: ImageInterface;
    imageWidth: number;
    imageHeight: number;
  }[];
  // For Preview
  subHeading?: PortableText;
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  theme: {
    name: string;
  };
}

const VideoSlider: FunctionComponent<GenericSliderInterface> = ({
  bgColor,
  headingColor,
  headline,
  _rawSubHeading,
  subHeading,
  content,
  sliderType,
  theme,
  slidesQuantity
}) => {
  const data = useStaticQuery(graphql`
    query {
      tick: sanityImageAsset(_id: { eq: "image-1c61f7954eede2dad4cac5d15b5f2a90e64657b1-748x947-png" }) {
        url
        title
      }
    }
  `);

  const imageUrl = data?.tick?.url + "?fm=webp";
  const text = _rawSubHeading || subHeading;

  // Move it out to utility
  const createThemeClassName = (className: string) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  const createTextThemeClassName = (className: string) => {
    return className ? `text--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  return (
    <section
      style={{
        backgroundImage: sliderType.name === "playlist" ? `url(${imageUrl})` : "none",
        background: bgColor ? bgColor.value : "none"
      }}
      className={`generic-slider video-slider ${createThemeClassName(theme?.name)} ${
        headingColor && createTextThemeClassName(headingColor.title)
      }`}
      data-testid="generic-slider"
    >
      <Container fluid>
        <Row>
          <Col>
            <h2 data-testid="generic-slider-headline video-slider-headline">
              {headline.primaryText.toUpperCase()}{" "}
              {headline.secondaryText && (
                <span className="move-it-1" aria-hidden="true">
                  {" "}
                  {headline.secondaryText.toUpperCase()}
                </span>
              )}
              {headline.tertiaryText && (
                <span className="move-it-2" aria-hidden="true">
                  {" "}
                  {headline.tertiaryText.toUpperCase()}{" "}
                </span>
              )}
            </h2>
            {text && (
              <div className="sub-heading">
                <BlockContent blocks={text} />
              </div>
            )}
            {content && (
              <SliderBlock
                data={content}
                type="videoSlide"
                slidesPerView={slidesQuantity}
                variation={sliderType.name}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VideoSlider;
